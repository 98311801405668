<template>
  <g>
    <!-- door handle BEGIN -->
    <!-- .handle_cover, .handle_grip {fill: {{ isBlack ? '#000' : 'url(#handle-gradient)' }};} -->
    <svg:style>
      .handle_cover_dublin,
      .handle_grip_dublin {
        stroke: #aeaeae;
        stroke-miterlimit: 10;
        stroke-width: 0.25px;
      }
      .handle_cover_dublin,
      .handle_grip_dublin {
        fill: url(#handle-gradient);
      }
      .handle_shadow,
      .door_lock_shadow {
        mix-blend-mode: multiply;
      }
      .handle_shadow {
        opacity: 0.35;
      }
      .keyhole {
        fill: #424242;
      }
      .door_lock_shadow {
        opacity: 0.6;
        mask: url(#mask);
      }
      .door_lock_shadowLeft {
        opacity: 0.6;
        mask: url(#maskLeft);
      }
      .keyhole_shadow {
        filter: url(#keyhole_shadow_mask);
      }
      .keyhole_shadowLeft {
        filter: url(#keyhole_shadow_mask);
      }
    </svg:style>

    <linearGradient id="handle-gradient" x1="0" y1="0" x2="100%" y2="0">
      <stop offset="0.01" stop-color="#babbbc" />
      <stop offset="0.29" stop-color="#97999d" />
      <stop offset="0.65" stop-color="#bdbdc1" />
      <stop offset="0.87" stop-color="#9d9fa2" />
      <stop offset="1" stop-color="#a6a7aa" />
      <stop offset="1" stop-color="#c2c5c9" />
    </linearGradient>

    <g
      v-if="
        (doorMirrorType !== 'mirrored' && viewType.id === '3') ||
          (doorMirrorType !== 'mirrored' && viewType.id === '5') ||
          (doorMirrorType === 'mirrored' && viewType.id === '4') ||
          (doorMirrorType === 'mirrored' && viewType.id === '6')
      "
    >
      <polyline
        class="handle_cover_dublin"
        :style="
          `fill: ${isBlack ? '#000' : ''} !important; stroke: ${
            isBlack ? '#000' : ''
          } !important;`
        "
        :points="
          `
          ${handleX1 + 19.66} ${handleY1 + 144.96}
          ${handleX1 + 19.66} ${handleY1 + 134.11}
          ${handleX1 + 14.07} ${handleY1 + 134.11}
          ${handleX1 + 14.07} ${handleY1 + 168.41}
          ${handleX1 + 19.66} ${handleY1 + 168.41}
          ${handleX1 + 19.66} ${handleY1 + 148.46}
        `
        "
      />
      <g>
        <image
          class="handle_shadow"
          width="25"
          height="11"
          :x="handleX1 - 4.13"
          :y="handleY1 + 143.13"
          xlink:href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABkAAAALCAYAAACK9ybzAAAACXBIWXMAAAsSAAALEgHS3X78AAAAoElEQVQ4T73TsQ2DMBCFYUJB6cKiySTMkDQwDutAjcg8mSBCmQClMP9ZD5TaCUb6XGDdO53BRQihMDwXKf8gZh3ZamAbFRw86h945VheqfzY1V5c0eCGFl2CVvWN8irlx8Vpo8eIGY8Es+p75bm9iR2VjXjHgCcWvBMsqh+U55UfFztLG3XCCys+CVbVT8qrv5ucPkmWb3L+35XlnuS48Ru/F4J+ZPkKegAAAABJRU5ErkJggg=="
        />
        <rect
          class="handle_grip_dublin"
          :x="handleX1 - 1.2"
          :y="handleY1 + 144.96"
          width="18.18"
          height="3.5"
          :style="
            `fill: ${isBlack ? '#000' : ''} !important; stroke: ${
              isBlack ? '#000' : ''
            } !important;`
          "
        />
      </g>
      <g class="door_lock_shadow">
        <path
          :d="
            `
            M${handleX1 + 18.1},${handleY1 + 157.08}
            a1.39,1.39,0,1,0-2.06,1.21
            v1.61
            h0
            a0.68,0.68,0,0,0,1.36,0
            v-1.62
            A1.38,1.38,0,0,0,${handleX1 + 18.1},${handleY1 + 157.08}
            Z
          `
          "
        />
      </g>
      <rect
        class="keyhole"
        :x="handleX1 + 15.5"
        :y="handleY1 + 156.9"
        width="1.5"
        height="0.4"
        rx="0.18"
        ry="0.18"
      />
    </g>
    <g
      v-if="
        (doorMirrorType !== 'mirrored' && viewType.id === '4') ||
          (doorMirrorType !== 'mirrored' && viewType.id === '6') ||
          (doorMirrorType === 'mirrored' && viewType.id === '3') ||
          (doorMirrorType === 'mirrored' && viewType.id === '5')
      "
    >
      <polyline
        class="handle_cover_dublin"
        :style="
          `fill: ${isBlack ? '#000' : ''} !important; stroke: ${
            isBlack ? '#000' : ''
          } !important;`
        "
        :points="
          `
          ${handleX1 + 15.66} ${handleY1 + 144.96}
          ${handleX1 + 15.66} ${handleY1 + 134.11}
          ${handleX1 + 10.07} ${handleY1 + 134.11}
          ${handleX1 + 10.07} ${handleY1 + 168.41}
          ${handleX1 + 15.66} ${handleY1 + 168.41}
          ${handleX1 + 15.66} ${handleY1 + 148.46}
        `
        "
      />
      <g>
        <image
          class="handle_shadow"
          width="25"
          height="11"
          :x="handleX1 + 8.13"
          :y="handleY1 + 143.13"
          xlink:href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABkAAAALCAYAAACK9ybzAAAACXBIWXMAAAsSAAALEgHS3X78AAAAoElEQVQ4T73TsQ2DMBCFYUJB6cKiySTMkDQwDutAjcg8mSBCmQClMP9ZD5TaCUb6XGDdO53BRQihMDwXKf8gZh3ZamAbFRw86h945VheqfzY1V5c0eCGFl2CVvWN8irlx8Vpo8eIGY8Es+p75bm9iR2VjXjHgCcWvBMsqh+U55UfFztLG3XCCys+CVbVT8qrv5ucPkmWb3L+35XlnuS48Ru/F4J+ZPkKegAAAABJRU5ErkJggg=="
        />
        <rect
          class="handle_grip_dublin"
          :x="handleX1 + 11.48"
          :y="handleY1 + 144.96"
          width="18.18"
          height="3.5"
          :style="
            `fill: ${isBlack ? '#000' : ''} !important; stroke: ${
              isBlack ? '#000' : ''
            } !important;`
          "
        />
      </g>
      <g class="door_lock_shadow">
        <path
          :d="
            `
            M${handleX1 + 14.12},${handleY1 + 157.08}
            a1.39,1.39,0,1,0-2.06,1.21
            v1.61
            h0
            a0.68,0.68,0,0,0,1.36,0
            v-1.62
            A1.38,1.38,0,0,0,${handleX1 + 14.12},${handleY1 + 157.08}
            Z
          `
          "
        />
      </g>
      <rect
        class="keyhole"
        :x="handleX1 + 12.1"
        :y="handleY1 + 156.9"
        width="1.5"
        height="0.4"
        rx="0.18"
        ry="0.18"
      />
    </g>
  </g>
</template>

<script>
export default {
  props: {
    isBlack: {
      type: Boolean,
      default: false
    },
    handleX: {
      type: Number,
      default: 0
    },
    handleY: {
      type: Number,
      default: 0
    },
    viewType: {
      type: Object,
      default: () => ({})
    },
    doorMirrorType: {
      type: String,
      default: ''
    }
  },
  computed: {
    handleX1() {
      return this.handleX - 11.4;
    },
    handleY1() {
      return this.handleY - 65.5;
    }
  }
};
</script>
